<template>
  <div>
    <el-form :model="create" label-position="center">

      <el-form-item label="学校名称 填写全名:" style="margin-left: 30px;">
        <el-input v-model.trim="create.name" placeholder="(如:英才小学桃山校区)" type="text" style="width:400px;float:left" />
      </el-form-item>

      <el-form-item label="校区级别:" style="margin-left: 30px;">
        <label slot="label">校区级别 :&emsp;&emsp;&emsp;&emsp;</label>
        <el-select v-model="create.type" clearable placeholder="(请选择校区级别)" style="width:400px;float:left">
          <el-option v-for="item in school_type" :key="item.ID" :label="item.name" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>

<!--      <el-form-item label="学校混合:" style="margin-left: 30px;">
        <label slot="label">学校混合 :&emsp;&emsp;&emsp;&emsp;</label>
        <el-select v-model="create.mix" clearable placeholder="(不选择默认不混合)" style="width:400px;float:left">
          <el-option v-for="item in school_mix" :key="item.ID" :label="item.mname" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item> -->

    </el-form>
    <el-button type="primary" style="margin-left:10px" @click="Create()">创建</el-button>
  </div>
</template>

<script>
  import {
    schoolCreateCampus,
  } from '@/api/TeacherApi.js'
  export default {
    data() {
      return {
        create: {},
        school_type: [{
            ID: 1,
            name: '幼儿园'
          },
          {
            ID: 2,
            name: '小学'
          },
          {
            ID: 3,
            name: '初中'
          },
          {
            ID: 4,
            name: '高中'
          },
          {
            ID: 5,
            name: '大学'
          },
        ], //校区类别
        school_mix: [
          {
            ID: 1,
            mname: '小学初中混合'
          },
          {
            ID: 2,
            mname: '初中高中混合'
          },
          {
            ID: 3,
            mname: '小学初中高中混合'
          },
        ], //学校混合
      }
    },
    methods: {
      Create() {
        console.log(this.create)
        if (this.create.name == '' || this.create.name == undefined || this.create.type == '' || this.create.type ==
          undefined ) {
          this.$message.error('请填写完整信息')
          return false;
        }

        const data = {
          campus_name: this.create.name,
          campus_type: this.create.type,
          // campus_mix: this.create.mix =="" ? 0 : this.create.mix == undefined ? 0 :this.create.mix
        }
        schoolCreateCampus(data).then(res => {
          if (res.data == true) {
            this.$message({
              message: '创建成功',
              type: 'success'
            })
            this.create.name = ''
            this.create.type = ''
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
    }
  }
</script>
